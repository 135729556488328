const RecallDomyosStrap = () => {
  return (
    <>
      <div
        id="recall-domyos-strap-training"
        className="recall section-anchor hr mt-[14px]"
      >
        <div className="av-row">
          <div className="av-col-xs-24">
            <h2 className="text-gray italic xl:text-lg lg:text-lg md:text-lg !text-[17px] font-semibold text-left">
              RECALL DOMYOS STRAP TRAINING 100
            </h2>
            <p>
              Caro(a) Cliente Esportista, como parte de nosso controle de
              segurança e qualidade, e diálogo constante e transparente com
              nossos clientes, as equipes de CROSS TRAINING / BODY BUILDING
              tomaram conhecimento de que esse produto não atinge nosso nível
              desejado de segurança. Descobrimos que, em casos muito raros, o
              mosquetão pode quebrar e causar uma queda.
            </p>

            <p className="text-left text-xs mt-4">
              <strong className="font-bold text-sm my-[14px]">
                Descrição:
              </strong>
            </p>
            <ul className="recall_description text-left pl-10">
              <li className="text-sm">
                Nome do produto: DOMYOS STRAP TRAINING 100
              </li>
              <li className="text-sm">Marca: Domyos</li>
              <li className="text-sm">Referência do produto: 8360619</li>
              <li className="text-sm">Código do item: 2046657</li>
              <li className="text-sm">
                Período: Adquiridos entre 01/01/2016 a 28/02/2019
              </li>
              <p className="text-sm mt-[14px]">Por isso, pedimos que:</p>
              <p className="text-sm">- Não utilize mais o produto.</p>
              <p className="text-sm mb-[14px]">
                - Devolva seu produto na loja Decathlon mais próxima e solicite
                o reembolso ou um vale troca caso queira adquirir outros produto
                na loja.
              </p>
            </ul>
          </div>
        </div>
        <div className="av-row flex items-center justify-center xl:justify-start lg:justify-start md:justify-start">
          <div className="av-col-xs-24 av-col-md-14 column ">
            <iframe
              title="decathlon"
              width="100%"
              height="340"
              src="https://www.youtube.com/embed/EqGkwPfbEFs?rel=0&amp;modestbranding=1&amp;controls=0&amp;showinfo=0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RecallDomyosStrap
