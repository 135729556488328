const RecallDomyosStrap = () => {
  return (
    <>
      <div
        id="recall-domyos-strap-training"
        className="recall section-anchor hr mt-[14px]"
      >
        <div className="av-row">
          <div className="av-col-xs-24">
            <h2 className="text-gray italic xl:text-lg lg:text-lg md:text-lg !text-[17px] font-semibold text-left">
              RECALL ROCKRIDER XC 500S MTB
            </h2>
            <div className="av-row mt-4 flex items-center justify-center xl:justify-start lg:justify-start md:justify-start">
              <div className="av-col-xs-24 av-col-md-14 column ">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/rockRider_01.png"
                  alt=""
                />
              </div>

              <div className="av-col-xs-24 av-col-md-14 column ">
                <img
                  src="https://decathlonstore.vteximg.com.br/arquivos/rockRider_02.png"
                  alt=""
                />
              </div>
            </div>
            <p className="mt-4">
              A Decathlon convoca os consumidores do produto Bicicleta Rockrider
              XC500S MTB a comparecerem à loja para troca da suspensão dianteira
              do produto, de forma gratuita.
            </p>

            <p className="text-left text-xs mt-4">
              <strong className="font-bold text-sm my-[14px]">
                Descrição:
              </strong>
            </p>
            <ul className="recall_description text-left pl-10">
              <li className="text-sm">Nome do produto: Bicicleta MTB</li>
              <li className="text-sm">Marca: Rockrider</li>
              <li className="text-sm">
                Data de Fabricação: 21/02/2021 a 16/02/2022
              </li>
            </ul>

            <p className="text-left text-xs mt-4">
              <strong className="font-bold text-sm my-[14px]">
                Descrição do defeito:
              </strong>
              <span className="text-left text-sm my-[14px] pl-1">
                durante teste de qualidade da bicicleta, foi identificada uma
                falha na suspensão dianteira.
              </span>
            </p>

            <p className="text-left text-xs mt-4">
              <strong className="font-bold text-sm my-[14px]">
                Descrição de riscos apresentados:
              </strong>
              <span className="text-left text-sm my-[14px] pl-1">
                é possível que a suspensão se desgaste antes do tempo esperado e
                trinque durante o uso. Essa trinca, por sua vez, pode resultar
                em uma quebra repentina da suspensão e gerar um acidente com o
                praticante.
              </span>
            </p>

            <p className="text-left text-xs mt-4">
              <strong className="font-bold text-sm my-[14px]">
                Início do atendimento:
              </strong>
              <span className="text-left text-sm my-[14px] pl-1">
                a ser confirmada
              </span>
            </p>

            <p className="text-left text-xs mt-4">
              <strong className="font-bold text-sm my-[14px]">
                Medidas preventivas:
              </strong>
              <span className="text-left text-sm my-[14px] pl-1">
                interromper o uso da bicicleta e procurar uma de nossas lojas ou
                central de atendimento para checar se o seu produto tem o número
                de série afetado.
              </span>
            </p>

            <p className="text-left mt-4 ">
              Para a troca da peça, dirija-se a qualquer loja Decathlon ou entre
              em contato com o nosso time de Atendimento ao Cliente Esportista.
            </p>

            <p className="text-left mt-4 ">
              <strong>WhatsApp: 55 11 94731-2606</strong>
            </p>
            <p>De segunda a sábado, de 9h às 18h</p>
            <p>E-mail, de segunda a sábado, de 9h às 22h</p>
            <p>
              <strong>Site: </strong>
              <a href="http://www.decathlon.com.br/atendimento">
                http://www.decathlon.com.br/atendimento
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecallDomyosStrap
