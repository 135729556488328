import { Image } from 'src/components/ui/Image'

const RecallOnNight = () => {
  return (
    <>
      <div
        id="lanterna-de-cabeca-onnight"
        className="lanterna-de-cabeca-onnight section-anchor hr"
      >
        <div className="av-row">
          <div className="av-col-xs-24">
            <h2 className="lanterna-de-cabeca-onnight_title text-gray italic font-semibold text-left xl:text-lg lg:text-lg md:text-lg !text-[17px]">
              CAMPANHA DE QUALIDADE: Corda EDGE 9mm x 80m e EDGE 9mm x 100m
              SIMOND
            </h2>
            <p className="text-sm my-[14px]">
              Data de lançamento da Campanha: 17 de outubro de 2023
            </p>
            <p className="text-sm my-[14px]">
              <strong>Olá, esportista!</strong>
            </p>
            <p className="text-sm my-[14px]">
              <strong>
                A segurança na prática da escalada é a nossa prioridade e vem em
                primeiro lugar.
              </strong>
            </p>
          </div>
        </div>
        <p className="text-sm my-[14px]">
          Informamos que, caso tenha comprado a{' '}
          <strong>corda EDGE 9mm x 80m</strong> ou a{' '}
          <strong>EDGE 9mm x 100m SIMOND</strong> entre 1º de Janeiro de 2022 e
          17 de outubro de 2023, essa comunicação é direcionada à você.
        </p>
        <p className="text-sm my-[14px]">
          Por meio dos nossos processos de garantia de qualidade, identificamos
          que a capa da corda pode desgastar-se precocemente e, durante a
          prática de escalada, deixar de fornecer a proteção necessária para o
          núcleo da corda.
        </p>
        <p className="text-sm my-[14px]">
          <strong>
            Embora esta corda esteja em conformidade com as normas atuais, ela
            não atende aos nossos padrões de qualidade e ao nosso compromisso de
            garantir a máxima segurança aos escaladores.
          </strong>
        </p>
        <p className="text-sm my-[14px]">
          Assim , como medida preventiva, pedimos que interrompa o uso do
          produto imediatamente.
        </p>
        <p className="text-sm my-[14px]">
          Reforçamos que, no Brasil, somente 8 cordas foram comercializadas e já
          atuamos diretamente com esses clientes para devolução, porém, caso
          você tenha comprado essa corda em uma loja Decathlon fora do Brasil,
          como medida preventiva, pedimos que interrompa o uso do produto
          imediatamente e siga o processo de devolução conforme instruções
          abaixo.
        </p>

        <div className="av-row" />
        <div className="av-row flex items-center justify-center">
          <div className="image av-col-xs-24 av-col-sm-10 flex">
            <span className="flex flex-col items-center">
              <Image
                src="https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/14bd8cdc-86ab-4d7d-875e-b78f85b3ce1c___483fbd30d382403711144944f58dc023.jpeg"
                alt="Recall Corda EDGE 9mm x 80m"
                width={180}
                height={181}
                loading="lazy"
              />
              <span className="text-center text-xs">
                model code 8612487 <br /> item code 4196528
              </span>
            </span>
            <span className="flex flex-col items-center">
              <Image
                src="https://decathlonstore.vtexassets.com/assets/vtex.file-manager-graphql/images/1f6ca0cc-0474-47cd-ada7-430f2c915857___dc68905a6ec8f85938ab38303077eb26.jpeg"
                alt="Recall Corda EDGE 9mm x 100m"
                width={180}
                height={181}
                loading="lazy"
              />
              <span className="text-center text-xs">
                model code 8612488 <br /> item code 4196529
              </span>
            </span>
          </div>
        </div>
        <p className="text-sm my-[14px]">
          Lhe convidamos a devolver o produto em uma loja Decathlon mais próxima
          ou solicitar a retirada em sua residência. O serviço de retirada será
          agendado pela equipe de Serviço de Atendimento ao Cliente da
          Decathlon, para isso, pedimos que entre em contato conosco pelo link:{' '}
          <a
            className="text-blue"
            href="https://www.decathlon.com.br/atendimento"
          >
            decathlon.com.br/atendimento
          </a>
        </p>
        <p className="text-sm my-[14px]">
          Agradecemos sua compreensão e pedimos desculpas por qualquer
          inconveniente que isso possa causar.
        </p>
        <p className="text-sm my-[14px]">Estamos aqui para ajudá-lo.</p>
        <p className="text-sm">
          Desejamos ótimas e seguras aventuras na montanha!
        </p>
      </div>
    </>
  )
}

export default RecallOnNight
