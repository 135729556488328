import { Image } from 'src/components/ui/Image'

const RecallOnNight = () => {
  return (
    <>
      <div
        id="lanterna-de-cabeca-onnight"
        className="lanterna-de-cabeca-onnight section-anchor hr"
      >
        <div className="av-row">
          <div className="av-col-xs-24">
            <h2 className="lanterna-de-cabeca-onnight_title text-gray italic  font-semibold text-left xl:text-lg lg:text-lg md:text-lg !text-[17px]">
              RECALL: LANTERNA DE CABEÇA ONNIGHT 410
            </h2>
            <p>
              Caro(a) Cliente Esportista, a empresa Decathlon convoca os
              consumidores do produto LANTERNA DE CABEÇA ONNIGHT 410, de forma
              gratuita, a solicitar o devido reembolso.
            </p>
          </div>
        </div>
        <p className="text-left text-xs mt-4">
          <strong className="font-bold text-sm my-[14px]">Descrição:</strong>
        </p>
        <ul className="recall_description text-left pl-10">
          <li className="text-sm">
            Nome do produto: LANTERNA DE CABEÇA ONNIGHT 410
          </li>
          <li className="text-sm">Marca: Kalenji e Geonaute</li>
          <li className="text-sm">
            Referência do produto: 8485678, 8302506, 8364850 e 8503711
          </li>
          <li className="text-sm">
            Período: Adquiridos entre 18/02/2014 à 20/09/2018
          </li>
        </ul>
        <div className="av-row" />
        <div className="av-row flex items-center justify-center xl:justify-start lg:justify-start md:justify-start">
          <div className="image av-col-xs-24 av-col-sm-10">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/recall-lanterna-on-night-410.png"
              alt="Recall Lanterna de Cabeça OnNight"
              width={180}
              height={181}
              loading="lazy"
            />
          </div>
        </div>
        <p className="text-sm my-[14px]">
          <strong>Descrição do defeito:</strong>Foi identificado em 1 (um) caso
          que a bateria super aqueceu durante o processo de recarga.
        </p>
        <p className="text-sm my-[14px]">
          <strong>Descrição dos riscos apresentados:</strong>O superaquecimento
          da bateria durante a recarga, pode gerar fogo, o que gera risco
          potencial de queimadura ao usuário.
        </p>
        <p className="text-sm my-[14px]">
          <strong>Medidas preventivas:</strong>Cessar imediatamente o uso do
          produto e buscar uma de nossas lojas ou a central de atendimento.
        </p>
        <p className="text-sm">Por isso, pedimos que:</p>
        <p className="text-sm">- Não utilize mais o produto.</p>
        <p className="text-sm">
          - Devolva seu produto na loja Decathlon mais próxima e solicite o
          reembolso ou um vale troca caso queira adquirir outros produto na
          loja.
        </p>
      </div>
    </>
  )
}

export default RecallOnNight
