import { Image } from 'src/components/ui/Image'

const RecallRocky = () => {
  return (
    <>
      <div
        id="recall-mosquetao-rocky"
        className="recall-mosquetao-rocky section-anchor hr"
      >
        <div className="av-row">
          <div className="av-col-xs-24">
            <h2 className="text-gray italic font-semibold text-left xl:text-lg lg:text-lg md:text-lg !text-[17px]">
              RECALL: MOSQUETÃO COM ROSCA ROCKY
            </h2>
            <p>
              Caro(a) Cliente Esportista, a empresa Decathlon convoca os
              consumidores do produto, de forma gratuita, realizar a troca do
              produto ou solicitar o devido reembolso.
            </p>

            <p className="text-left text-xs my-[14px]">
              <strong className="font-bold text-sm">Descrição:</strong>
            </p>
            <ul className="recall_description text-left pl-10">
              <li className="text-sm">
                Nome do produto: MOSQUETÃO COM ROSCA ROCKY
              </li>
              <li className="text-sm">Marca: Simond</li>
              <li className="text-sm">
                Referência do produto: 8292181 e 8292246
              </li>
              <li className="text-sm">Código do item: 2046657</li>
              <li className="text-sm">
                Período: Adquiridos entre 05/12/2018 a 04/07/2019
              </li>
              <li className="text-sm">Cores: Amarelo e Azul</li>
            </ul>
          </div>
        </div>
        <div className="av-row">
          <div className="av-col-xs-24 av-col-md-10 column column__text">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/mosquetoes-recall-decathlon.png"
              alt="Recall DST"
              width={288}
              height={164}
              loading="lazy"
            />
          </div>
        </div>
        <p className="text-left text-xs my-[14px]">
          <strong className="font-bold text-xs">Descrição do defeito:</strong>
          Durante o processo de qualidade e segurança na produção a equipe
          Simond identificou que pouquíssimas peças apresentam a “rosca” (trava)
          montada de forma invertida, conforme imagem ao lado.{' '}
          <strong>Descrição dos riscos apresentados:</strong> Eventuais peças
          com a trava montada na posição incorreta estão sujeitas a uma abertura
          imprevisível durante a prática da escalada o que poderia causar queda.
        </p>
        <p className="text-left text-xs my-[14px]">
          <strong className="font-bold text-xs">
            Como saber se o mosquetão está ou não envolvido no processo de
            RECALL?
          </strong>
        </p>
        <p className="text-xs">
          1º Posicionar o mosquetão “na vertical” (como indicado pela foto).
        </p>
        <p className="text-xs">2º Verificar a posição da “rosca” (trava)</p>
        <p className="text-xs">
          - se a parte “oca” (cavada) estiver para cima, o mosquetão está OK
        </p>
        <p className="text-xs">
          - se a parte plana estiver para cima, o mosquetão não está OK
        </p>
        <div className="av-row my-[14px]">
          <div className="av-col-xs-24 av-col-md-10 column column__text">
            <Image
              src="https://decathlonproqa.vtexassets.com/arquivos/pormenorizada-mosquetao.png"
              alt="Recall DST"
              width={288}
              height={292}
              loading="lazy"
            />
          </div>
        </div>
        <p className="text-xs my-[14px]">
          Por isso, pedimos que verifique imediatamente o produto e cessar o uso
          do mesmo caso seja verificado que esse apresenta a “rosca” (trava) na
          posição incorreta.
        </p>
        <div className="av-row flex items-center justify-center xl:justify-start lg:justify-start md:justify-start">
          <div className="av-col-xs-24 av-col-md-14 column">
            <iframe
              title="decathlon"
              width="100%"
              height="340"
              src="https://www.youtube.com/embed/mWXLEVYRbRQ?rel=0&amp;modestbranding=1&amp;controls=0&amp;showinfo=0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RecallRocky
